//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-720:_344,_2604,_8140,_1100,_7128,_8448,_9328,_8084;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-720')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-720', "_344,_2604,_8140,_1100,_7128,_8448,_9328,_8084");
        }
      }catch (ex) {
        console.error(ex);
      }